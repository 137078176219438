import { useState, useRef } from 'react';
import { DataTable, DataTableRowEvent, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useCoreUsers } from "../../hooks";
import { Affix } from "antd";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useCoreUsersApplications } from "../../hooks";
import { Tag } from 'primereact/tag';
import { ApplicationUserinfo, GetInfo } from './components'
import { Dialog } from "primereact/dialog";

interface User {
    uid: string;
    email: string;
    roll: string;
    status: string;
    approved: boolean;
    change_password: boolean;
}

interface Representative {
    name: string;
    image: string;
}

interface Customer {
    id: number;
    uid: string;
    email: string;
    name: string;
    roll: string;
    date: string;
    status: string;
    verified: boolean;
    activity: number;
    representative: Representative;
    balance: number;
}

const Workers = ( {relad}: Props) => {
    // const { usersApplications, reloadUsersApplications } = useCoreUsersApplications();
    const { users, isLoading } = useCoreUsers();
    const toast = useRef<Toast>(null);
    // const [selectedUser, setSelectedUser] = useState<User | null>(null);
    // const [dialogVisible, setDialogVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    // const onRowClick = (event: DataTableRowEvent) => {
    //     setSelectedUser(event.data as User);
    //     setDialogVisible(true);
    // };

    const header = (
        <div className='w-full'>
            <Affix offsetTop={44} onChange={(affixed) => console.log(affixed)}>
                <div className="flex justify-between items-center w-full" style={{ backgroundColor: "#F8F9FA" }}>
                    <div className="flex w-1/4 items-center">
                        <span className="p-input-icon-left w-full">
                            <i className="pi pi-search pl-2" />
                            <InputText placeholder="Keyword Search" className='w-full pl-8' />
                        </span>
                    </div>
                </div>
            </Affix>
        </div>
    );

    const defaultFilters: DataTableFilterMeta = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        }        
    };

    const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);

    const getSeverity = (status: string) => {
        switch (status) {
            case 'hiring':
                return 'danger';

            case 'hired':
                return 'success';

            case 'new':
                return 'info';

            case 'application':
                return 'warning';

            case 'renewal':
                return null;
        }
    };

    const getRoll = (status: string) => {
        switch (status) {
            case 'tmp':
                return 'warning';

            case 'HR Assistant':
                return 'success';

            case 'new':
                return 'info';


            case 'renewal':
                return null;
        }
    };

    const rollBodyTemplate = (rowData: Customer) => {
        return <Tag value={rowData.roll} severity={getRoll(rowData.roll)} />;
    };
    const rollBodyName = (rowData: Customer) => {
        return <GetInfo uid={rowData.uid} date="fullname" />
    };
    const rollBodyApplied = (rowData: Customer) => {
        return <GetInfo uid={rowData.uid} date="position_applied" />
    };

    const statusBodyTemplate = (rowData: Customer) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    };

    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showUid, setShowUid] = useState<string>("0");

    const showUserAplication = (event: DataTableRowEvent) => {
        setShowUid(event.data.uid);
        setShowInfo(true);
      };

    return (

        <div className='w-full mt-10'>
            <Toast ref={toast} />
            <Dialog
                // header="View user aplication"
                visible={showInfo}
                maximizable
                style={{ width: "95vw" }}
                onHide={() => setShowInfo(false)}
            >
                <ApplicationUserinfo uid={showUid} relad={relad} />
            </Dialog>

            {/* Test STAFF INFO */}
            <DataTable
                value={users?.users?.filter(user => user.roll !== "tmp")}
                onRowClick={showUserAplication}
                paginator
                showGridlines
                stripedRows
                selectionMode="single"
                rows={10}
                loading={loading}
                dataKey="ID"
                filters={filters}
                // filterDisplay="row"
                globalFilterFields={["email"]}
                header={header}
                emptyMessage="No customers found."
            >
                {/* <Column expander={allowExpansion} style={{ width: '5rem' }} /> */}
                <Column field="email" header="Email" />
                <Column header="Fullname" body={rollBodyName} />
                <Column field="nick" header="NickName" />
                <Column header="Position applied" body={rollBodyApplied} />
                <Column field="roll" header="Roll" body={rollBodyTemplate} />
                <Column field="status" header="Status" body={statusBodyTemplate} />
            </DataTable>
        </div>
    );
}

type Props = {
    relad(): void;
};

export { Workers };