import { useState } from "react";
import { Active } from "../../models";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetHiringUrls3 } from "../profile/hooks";
import { PdfViewer } from ".";
import user from "../../images/user.png";
import { Countdown } from ".";
import { PersonalInformation } from "./component/profile/PersonalInformation";

const Profile = ({ active }: Props) => {
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const { urlDoc } = useGetHiringUrls3();
  const [newNickname, setNewNickname] = useState(
    active?.activeUser?.User?.nick || ""
  );

  const handleSaveNickname = () => {
    console.log("Saving new nickname:", newNickname);
    setIsEditingNickname(false);
  };

  // -----------------
  const [pdfContent, setPdfContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState("");
  // -----------------
  const handleOpenModal = (file?: string) => {
    if (file !== "") {
      let url = `records/${active?.activeUser?.User?.uid}/${file}.pdf`;
      // Realizar una petición a la API de Go para descargar el PDF
      const fetchUrl = async () => {
        const result = await urlDoc({ key: url, duration: "1m" });
        if (result && result.url) {
          setPdfContent(result.url);
          setFileName(file ?? "");
          setIsOpen(true);
          // setDate("");
          if (file === "service_trainer_provider") {
            setDate(
              active?.activeUser?.Record?.necessary_documents
                .service_trainer_provider_date ?? ""
            );
          }
          if (file === "service_infection_control") {
            setDate(
              active?.activeUser?.Record?.necessary_documents
                .service_infection_control_date ?? ""
            );
          }
          if (file === "service_cpr_aed") {
            setDate(
              active?.activeUser?.Record?.necessary_documents
                .service_cpr_aed_date ?? ""
            );
          }
          if (file === "service_osha") {
            setDate(
              active?.activeUser?.Record?.necessary_documents
                .service_osha_date ?? ""
            );
          }
        }
      };
      fetchUrl();
    }
  };

  return (
    <div className="card bg-white p-6">
      <Dialog
        header={fileName}
        visible={isOpen}
        maximizable
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "70vw", "641px": "90vw" }}
        onHide={() => setIsOpen(false)}
      >
        <p className="m-0">
          <div className="w-full">
            {pdfContent !== "" && isOpen && <PdfViewer fileUrl={pdfContent} />}
          </div>
        </p>
      </Dialog>
      <div className="flex items-center gap-6 mb-8 p-4 bg-gray-50 rounded-xl">
        <div className="relative">
          <img
            src={user}
            alt={active?.activeUser?.User?.nick || "Profile"}
            className="w-24 h-24 rounded-full border-4 border-white shadow-md"
          />
          <div className="absolute -bottom-2 -right-2">
            <Button
              icon="pi pi-camera"
              className="p-button-rounded p-button-text"
              tooltip="Change photo"
            />
          </div>
        </div>
        <div>
          <div className="flex items-center gap-2">

            {isEditingNickname ? (
              <div className="flex items-center gap-2">
                <InputText
                  value={newNickname}
                  onChange={(e) => setNewNickname(e.target.value)}
                  className="text-xl font-semibold p-1 h-8"
                  autoFocus
                />
                <Button
                  icon="pi pi-check"
                  className="p-button-rounded p-button-text text-green-500 flex items-center justify-center w-8 h-8 min-w-[2rem]"
                  onClick={handleSaveNickname}
                  tooltip="Save"
                />
                <Button
                  icon="pi pi-times"
                  className="p-button-rounded p-button-text text-red-500 flex items-center justify-center w-8 h-8 min-w-[2rem]"
                  onClick={() => setIsEditingNickname(false)}
                  tooltip="Cancel"
                />
              </div>
            ) : (
              <>
                <h2 className="text-2xl font-bold text-gray-800 mb-1">
                  {active?.activeUser?.User?.nick}
                </h2>
                <Button
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-text text-gray-500 flex items-center justify-center w-8 h-8 min-w-[2rem]"
                  tooltip="Edit nickname"
                  tooltipOptions={{ position: "top" }}
                  onClick={() => setIsEditingNickname(true)}
                />
              </>
            )}
          </div>
          <p className="text-gray-500 flex items-center gap-2">
            <i className="pi pi-envelope"></i>
            {active?.activeUser?.User?.email}
          </p>
        </div>
      </div>

      <TabView className="profile-tabs bg-white shadow-md rounded-lg overflow-hidden">
        <TabPanel
          header="Personal Information"
          leftIcon="pi pi-user mr-2"
          className="bg-white shadow-sm rounded-lg "
          pt={{
            headerAction: ({ selected }: { selected: boolean }) => ({
              className: `p-1 rounded-t-lg transition-colors duration-200 border-b-4 border-blue-300 text-gray-600 hover:border-orange-300`,
            }),
          }}
        >
          <PersonalInformation active={active} />
        </TabPanel>
        <TabPanel
          header="Services"
          leftIcon="pi pi-file mr-2"
          className="bg-white shadow-sm rounded-lg pl-1"
          pt={{
            headerAction: ({ selected }: { selected: boolean }) => ({
              className: `p-1 rounded-t-lg transition-colors duration-200 border-b-4 border-blue-300 text-gray-600 hover:border-orange-300`,
            }),
          }}
        >
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
            <div className="space-y-6">
              <div
                className="stat bg-gray-100 hover:bg-gray-200 rounded-md"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenModal("service_trainer_provider");
                }}
              >
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_trainer_provider
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_trainer_provider
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>10 Hours from FCB</b>
                </div>
                <div className="stat-title">
                  <i>Accredited Trainer Provider</i>
                </div>
                <div className="stat-desc text-secondary flex">
                  Every Year
                  {active?.activeUser?.Record?.necessary_documents
                    .service_trainer_provider_date !== "" && (
                      <div className="flex">
                        -{" "}
                        <i>
                          Date obtained:{" "}
                          {
                            active?.activeUser?.Record?.necessary_documents
                              .service_trainer_provider_date
                          }
                        </i>
                        &nbsp;expires in: &nbsp;{" "}
                        <Countdown
                          date={
                            active?.activeUser?.Record?.necessary_documents.service_trainer_provider_date
                              .split("/")
                              .join("-") ?? "01-01-2024"
                          }
                          hour={0}
                          minutes={0}
                          seconds={0}
                          summ={1}
                          size="12px"
                        />
                      </div>
                    )}
                </div>
              </div>

              <div
                className="stat bg-gray-100 hover:bg-gray-200 rounded-md"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenModal("service_infection_control");
                }}
              >
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_infection_control
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_infection_control
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Infection Control</b>
                </div>
                <div className="stat-title">
                  <i>(Trainflorida)</i>
                </div>
                <div className="stat-desc text-secondary">
                  Every 3 Years
                  {active?.activeUser?.Record?.necessary_documents
                    .service_infection_control_date !== "" && (
                      <div className="flex">
                        -{" "}
                        <i>
                          Date obtained:{" "}
                          {
                            active?.activeUser?.Record?.necessary_documents
                              .service_infection_control_date
                          }
                        </i>
                        &nbsp;expires in: &nbsp;{" "}
                        <Countdown
                          date={
                            active?.activeUser?.Record?.necessary_documents.service_infection_control_date
                              .split("/")
                              .join("-") ?? "01-01-2024"
                          }
                          hour={0}
                          minutes={0}
                          seconds={0}
                          summ={1}
                          size="12px"
                        />
                      </div>
                    )}
                </div>
              </div>

              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_hiv_aids
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_hiv_aids
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>HIV / AIDS</b>
                </div>
                <div className="stat-title">
                  <i>Trainflorida</i>
                </div>
                <div className="stat-desc text-secondary">Only 1 Time</div>
              </div>
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_fars_cfars
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_fars_cfars
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>FARS / CFARS</b>
                </div>
                <div className="stat-desc text-secondary">Only 1 Time</div>
              </div>
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_access_civil_rights
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_access_civil_rights
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Access Civil Rights</b>
                </div>
                <div className="stat-desc text-secondary">Only 1 Time</div>
              </div>
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_security_awareness
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_security_awareness
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Security Awarenes</b>
                </div>

                <div className="stat-desc text-secondary">Only 1 Time</div>
              </div>
            </div>
            <div className="space-y-6">
              <div
                className="stat bg-gray-100 hover:bg-gray-200 rounded-md"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenModal("service_cpr_aed");
                }}
              >
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_cpr_aed
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_cpr_aed
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title ">
                  <b>CPR / AED &nbsp;</b>
                </div>
                <div className="stat-desc text-secondary">
                  Every 2 Years
                  {active?.activeUser?.Record?.necessary_documents
                    .service_cpr_aed_date !== "" && (
                      <div className="flex">
                        -{" "}
                        <i>
                          Date obtained:{" "}
                          {
                            active?.activeUser?.Record?.necessary_documents
                              .service_cpr_aed_date
                          }
                        </i>
                        &nbsp;expires in: &nbsp;{" "}
                        <Countdown
                          date={
                            active?.activeUser?.Record?.necessary_documents.service_cpr_aed_date
                              .split("/")
                              .join("-") ?? "01-01-2024"
                          }
                          hour={0}
                          minutes={0}
                          seconds={0}
                          summ={1}
                          size="12px"
                        />
                      </div>
                    )}
                </div>
              </div>
              <div
                className="stat bg-gray-100 hover:bg-gray-200 rounded-md"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleOpenModal("service_osha");
                }}
              >
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_osha
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_osha
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>OSHA</b>
                </div>
                <div className="stat-title">
                  <i>
                    (Occupational Exposure to <br /> Blood Borne Pathogens)
                  </i>
                </div>
                <div className="stat-desc text-secondary">
                  Every 3 Years
                  {active?.activeUser?.Record?.necessary_documents
                    .service_osha_date !== "" && (
                      <div className="flex">
                        -{" "}
                        <i>
                          Date obtained:{" "}
                          {
                            active?.activeUser?.Record?.necessary_documents
                              .service_osha_date
                          }
                        </i>
                        &nbsp;expires in: &nbsp;{" "}
                        <Countdown
                          date={
                            active?.activeUser?.Record?.necessary_documents.service_osha_date
                              .split("/")
                              .join("-") ?? "01-01-2024"
                          }
                          hour={0}
                          minutes={0}
                          seconds={0}
                          summ={1}
                          size="12px"
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_domestic_violence
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_domestic_violence
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title ">
                  <b>Domestic Violence, Substance Abuse</b>
                </div>
                <div className="stat-title ">
                  <i>Mental Health Disorder and Child Abuse</i>
                </div>
                <div className="stat-desc text-secondary">only 1 Time</div>
              </div>
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_hippa
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_hippa
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>HIPPA</b>
                </div>
                <div className="stat-desc text-secondary">Only 1 Time</div>
              </div>
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .service_deaf_hard
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .service_deaf_hard
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title ">
                  <b>Service Delivery</b>
                </div>
                <div className="stat-title ">
                  <i>for the Deaf or Hard-of-Hearing</i>
                </div>
                <div className="stat-desc text-secondary">only 1 Time</div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          header="Personal documents and other information"
          leftIcon="pi pi-file mr-2"
          className="bg-white shadow-sm rounded-lg pl-1"
          pt={{
            headerAction: ({ selected }: { selected: boolean }) => ({
              className: `p-1 rounded-t-lg transition-colors duration-200 border-b-4 border-blue-300 text-gray-600 hover:border-orange-300`,
            }),
          }}
        >
          <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
            <div className="space-y-6">
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents.resume
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents.resume
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Resume</b>
                </div>
                <div className="stat-desc text-secondary">
                  31 tasks remaining
                </div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .diploma_transcripts
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .diploma_transcripts
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title ">
                  <b>Diploma / Transcripts</b>
                </div>
                <div className="stat-desc text-secondary">
                  31 tasks remaining
                </div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .licenses_certifications
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .licenses_certifications
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Licenses / Certifications</b>
                </div>
                <div className="stat-desc text-secondary">
                  31 tasks remaining
                </div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents.course_fcb
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .course_fcb
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Course FCB</b>
                </div>
                <div className="stat-desc text-secondary">
                  31 tasks remaining
                </div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_medicaid_certification
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_medicaid_certification
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Medicaid Certification</b>
                </div>
                <div className="stat-desc text-secondary">If applicable</div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_medicaid_provider
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_medicaid_provider
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Medicaid Provider and NPI Number</b>
                </div>
                <div className="stat-desc text-secondary">If applicable</div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_drivers_license
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_drivers_license
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Driver’s License or Valid Picture ID</b>
                </div>
                <div className="stat-desc text-secondary"></div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_social_security_card
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_social_security_card
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Social Security Card</b>
                </div>
                <div className="stat-desc text-secondary"></div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_proof_legal_status
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_proof_legal_status
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Proof of Legal Status</b>
                </div>
                <div className="stat-title">
                  <i>
                    US Passport, Resident Card,Employment Authorization, etc.
                  </i>
                </div>
                <div className="stat-desc text-secondary"></div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_employee_id_badge
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_employee_id_badge
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Employee ID Badge</b>
                </div>
                <div className="stat-desc text-secondary"></div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_vehicle_registration
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_vehicle_registration
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Vehicle Registration</b>
                </div>
                <div className="stat-desc text-secondary">If applicable</div>
              </div>
              &nbsp;
              <div className="stat bg-gray-100 hover:bg-gray-200 rounded-md">
                <div className="stat-figure text-secondary">
                  <div
                    className={classNames(
                      "avatar",
                      active?.activeUser?.Record?.necessary_documents
                        .other_proof_insurance
                        ? "online"
                        : "offline"
                    )}
                  >
                    <div className="w-16">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className={classNames(
                          "w-16 h-16",
                          active?.activeUser?.Record?.necessary_documents
                            .other_proof_insurance
                            ? "text-warning"
                            : "text-gray-400"
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="stat-value"></div>
                <div className="stat-title">
                  <b>Proof of Insurance</b>
                </div>
                <div className="stat-desc text-secondary">If applicable</div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel
          header="Security"
          leftIcon="pi pi-lock mr-2"
          className="shadow-sm pl-1"
          pt={{
            headerAction: ({ selected }: { selected: boolean }) => ({
              className: `p-1 rounded-t-lg transition-colors duration-200 border-b-4 border-blue-300 text-gray-600 hover:border-orange-300`,
            }),
          }}
        >
          <div className="max-w-md mx-auto space-y-6">
            <form className="space-y-6">
              <div className="profile-field">
                <h3 className="text-sm font-medium text-gray-600 mb-2">
                  Current Password
                </h3>
                <InputText
                  type="password"
                  className="w-full p-4 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                  placeholder="Enter current password"
                />
              </div>
              <div className="profile-field">
                <h3 className="text-sm text-gray-500 mb-1">New Password</h3>
                <InputText
                  type="password"
                  className="w-full p-4 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                  placeholder="Enter new password"
                />
              </div>
              <div className="profile-field">
                <h3 className="text-sm text-gray-500 mb-1">Confirm Password</h3>
                <InputText
                  type="password"
                  className="w-full p-4 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all"
                  placeholder="Confirm new password"
                />
              </div>
              <div className="flex justify-end">
                <Button
                  label="Change Password"
                  icon="pi pi-lock"
                  className="p-button-primary text-lg px-3 py-3 rounded-md"
                  style={{ minWidth: "200px" }}
                />
              </div>
            </form>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};

type Props = {
  active?: Active;
  // relad(): void;
};

export { Profile };
