import { useState, useEffect, useRef } from 'react';
// -- Libs PrimeReact
import { Message } from 'primereact/message';
import { Dialog } from "primereact/dialog";
import { Calendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";
import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';



import { BillingBillerBusiness } from "./billingBillerBusiness";
import { BillingBillerTcm } from "./billingBillerTcm";


// New structure
import { Active, TcmLits } from "../../../models";
import { useModuleTcmAllTcms } from "../../../hooks/modules/tcm";
import { classNames } from 'primereact/utils';

// Añade esta función helper fuera del componente
const parseDate = (dateStr: string) => {
    if (!dateStr) return null;
    const [month, day, year] = dateStr.split('/');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

const BillingBiller = ({ active, relad }: Props) => {
    const [period, setPeriod] = useState<Nullable<(Date | null)[]>>(null);
    const { tcms, reloadTCMS } = useModuleTcmAllTcms();
    // All Billings Business
    const [visibleAllBill, setVisibleAllBill] = useState<boolean>(false);
    const [visibleConfigBuss, setVisibleConfigBuss] = useState<boolean>(false);
    const [business, setBusiness] = useState<string>("0");



    const [visibleDetails, setVisibleDetails] = useState<boolean>(false);
    const [tcm, setTcm] = useState<string>("0");
    const [tcmData, setTcmData] = useState<TcmLits>();

    const prevPeriodRef = useRef('');


    // Filtro
    const [searchTerm, setSearchTerm] = useState(''); // Nuevo estado para el término de búsqueda
    // Filtrar los tcms según el término de búsqueda

    const filteredTcms = tcms?.tcms?.filter(tcm =>
        tcm.info?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tcm.info?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tcm.info?.cell_phone?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        // Inicializar con el periodo del active
        if (active?.activeUser?.WeekActive?.start && active?.activeUser?.WeekActive?.end) {
            const startDate = parseDate(active.activeUser.WeekActive.start);
            const endDate = parseDate(active.activeUser.WeekActive.end);
            if (startDate && endDate) {
                const currentPeriodStr = `${startDate}-${endDate}`;
                if (currentPeriodStr !== prevPeriodRef.current) {
                    setPeriod([startDate, endDate]);
                    prevPeriodRef.current = currentPeriodStr;
                }
            }
        }


    }, [period, active?.activeUser?.WeekActive]);

    useEffect(() => {
        reloadTCMS();
    }, [relad]);

    return (
        <div className='p-5 mt-10 w-full'>
            <input
                type="text"
                placeholder="Search for Business..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Actualiza el término de búsqueda
                className="mb-4 p-2 border rounded"
            />
            <div className="grid grid-cols-8 gap-5 w-full">
                {filteredTcms?.map((tcms, k) => {
                    let header = () => (
                        <div className='w-full flex content-center items-center'>
                            <div className='w-2/3 flex'>
                                <i className='pi pi-building-columns mr-2' style={{ fontSize: '2rem' }} />
                                <Badge
                                    severity={(tcms.list_tcm?.length || 0) > 0 ? tcms.list_tcm?.some(tcm => tcm.bill?.billing?.biller_signature !== "data:image/png;base64,") ? "success" : "warning" : "success"}
                                    className={classNames(
                                        tcms.list_tcm?.some(tcm => tcm.bill?.billing?.biller_signature === "data:image/png;base64,") && "animate-blink"
                                    )}
                                />
                                <b>Business {k + 1}</b>
                            </div>
                            <div className='w-1/3 flex'>
                                <div
                                    className='flex cursor-pointer hover:text-orange-400'
                                    onClick={() => {
                                        setBusiness(tcms.info?.ID || "0");
                                        setVisibleAllBill(true);
                                    }}
                                >
                                    <i className='pi pi-list-check mr-2' />
                                    <b style={{ fontSize: "12px" }}>Billings</b>
                                </div>
                                <div
                                    className='flex cursor-pointer hover:text-orange-400'
                                    onClick={() => {
                                        setBusiness(tcms.info?.ID || "0");
                                        setVisibleConfigBuss(true);
                                    }}
                                >
                                    <i className='pi pi-cog pi-spin ml-5' />
                                </div>

                            </div>
                        </div>
                    )
                    return (
                        tcms.info && (
                            <div className="card col-span-2" key={tcms.info.ID}>
                                <Card title={header}>
                                    <p className="m-0 border-t">
                                        <i className='pi pi-user mr-5' /> {tcms.info.fullname}
                                        <hr />
                                        <i className='pi pi-phone mr-5' />{tcms.info.cell_phone}
                                        <hr />
                                        <i className='pi pi-envelope mr-5' />{tcms.info.email}
                                        <hr />
                                        <i className='pi pi-users mr-5' />{tcms.list_tcm?.length} Targeted Case Manager
                                        <hr className='mb-5' />
                                        {(tcms.list_tcm?.length || 0) > 0 ? (
                                            <div className='h-72'>
                                                <div className='w-full flex'>
                                                    <div className='w-1/6'>
                                                    </div>
                                                    <div className='w-4/6 font-bold'>
                                                        Name TCM
                                                    </div>
                                                    <div className='w-1/6 text-center font-bold mr-2'>
                                                        Clients
                                                    </div>
                                                </div>
                                                <hr />
                                                {tcms.list_tcm?.map((tcm) => (
                                                    <div
                                                        className='cursor-pointer hover:bg-gray-50'
                                                        onClick={() => {
                                                            setBusiness(tcms.info?.ID || "0");
                                                            setTcm(tcm.info?.ID || "0");
                                                            setTcmData(tcm);
                                                            setVisibleDetails(true);
                                                        }}
                                                    >
                                                        <div className='w-full flex'>
                                                            <div className='w-1/6'>
                                                                <Badge
                                                                    severity={tcm.bill?.billing?.biller_signature !== "data:image/png;base64," ? "success" : "warning"}
                                                                    className={classNames(
                                                                        tcm.bill?.billing?.biller_signature === "data:image/png;base64," && "animate-blink"
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className='w-4/6'>
                                                                {tcm.info?.fullname}
                                                            </div>
                                                            <div className='w-1/6 text-center'>
                                                                {tcm.clients?.length}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <Message text="No TCM found" className="w-full bg-gray-100 h-72" />
                                        )}

                                    </p>
                                </Card>
                            </div>
                        )
                    )
                })}
            </div>
            {/* Tools */}
            <Dialog
                header={() => {
                    return (
                        <div className='w-full flex items-center'>
                            <div className='w-1/3'>
                                <b>Business {business}</b>
                                <hr />
                                <b className='font-normal'>
                                    <b>TCM:</b> {tcmData?.info?.fullname}
                                    <i className='pi pi-phone ml-5' />{tcmData?.info?.cell_phone}
                                </b>
                            </div>
                            <div className='w-1/3 text-right'>
                                <b className='font-normal'>Select period:</b>
                                <Calendar
                                    pt={{
                                        root: { className: "ml-2 hidden" },
                                        input: { root: { className: "text-right border-primary border" } }
                                    }}
                                    autoFocus={false}
                                />
                                <Calendar
                                    selectionMode="range"
                                    value={period}
                                    onChange={(e) => setPeriod(e.value)}
                                    // readOnlyInput
                                    hideOnRangeSelection
                                    showWeek
                                    autoFocus={false}
                                    maxDate={parseDate(active?.activeUser?.WeekActive?.end || '') || undefined}
                                    showIcon
                                    pt={{
                                        root: { className: "ml-2" },
                                        input: { root: { className: "text-right border-primary border" } }
                                    }}
                                />
                            </div>
                        </div>
                    )
                }}
                visible={visibleDetails}
                maximizable
                style={{ width: "90vw" }}
                breakpoints={{ "960px": "90vw", "641px": "90vw" }}
                onHide={() => setVisibleDetails(false)}
            >
                {tcm !== "0" && <BillingBillerTcm id={tcm} active={active} relad={relad} period={period} />}
            </Dialog>
            <Dialog
                header={() => {
                    return (
                        <div className='w-full flex items-center'>
                            <div className='w-1/3'>
                                <b>{`Business Billing ${business}`}</b>
                            </div>
                            <div className='w-1/3 text-right'>
                                <b className='font-normal'>Select period:</b>
                                <Calendar
                                    pt={{
                                        root: { className: "ml-2 hidden" },
                                        input: { root: { className: "text-right border-primary border" } }
                                    }}
                                    autoFocus={false}
                                />
                                <Calendar
                                    value={period}
                                    onChange={(e) => setPeriod(e.value)}
                                    selectionMode="range"
                                    readOnlyInput
                                    hideOnRangeSelection
                                    showWeek
                                    maxDate={parseDate(active?.activeUser?.WeekActive?.end || '') || undefined}
                                    showIcon
                                    pt={{
                                        root: { className: "ml-2" },
                                        input: { root: { className: "text-right border-primary border" } }
                                    }}
                                    autoFocus={false}
                                />
                            </div>
                        </div>
                    )
                }}
                visible={visibleAllBill}
                maximizable
                style={{ width: "90vw" }}
                breakpoints={{ "960px": "90vw", "641px": "90vw" }}
                onHide={() => setVisibleAllBill(false)}
            >
                {business !== "0" && <BillingBillerBusiness id={business} active={active} relad={relad} period={period} />}
            </Dialog>
            <Dialog
                header={() => {
                    return (
                        <div className='w-full flex items-center'>
                            <div className='w-2/3'>
                                <b>{`Setting up Business ${business} collection`}</b>
                            </div>
                            
                        </div>
                    )
                }}
                visible={visibleConfigBuss}
                maximizable
                style={{ width: "50vw" }}
                breakpoints={{ "500px": "50vw", "641px": "90vw" }}
                onHide={() => setVisibleConfigBuss(false)}
            >
                {business !== "0" && (
                    <div>
                        Rent collection: $300
                        <br/>
                        Other: $200
                    </div>
                )}
            </Dialog>
        </div>
    );
}
type Props = {
    active?: Active;
    relad(): void;
};
export { BillingBiller }






